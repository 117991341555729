import React, { useState } from 'react'
import '../scss/main.scss'
import { useDevices } from '../util'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function SplitPageCategories ({ data }) {
  const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se'
  const [mobile, tablet, desktop, wideScreen] = useDevices()
  const [show, setShow] = useState()
  const [currentContent, setCurrentContent] = useState(0)
  const [state, setState] = useState(false)

  let button
  let buttonLink
  let ingress
  let array = data.Category.map(oneCategory => {
    let contentArr = oneCategory.Content?.split('\n')
    let icon = oneCategory?.Icon?.url
    button = oneCategory.Button
    buttonLink = oneCategory.Button_Link
    ingress = oneCategory.Ingress
    return [oneCategory.Title, contentArr, icon, button, buttonLink, ingress]
  })

  let ulContent = (
    <div>
      {(desktop || wideScreen) && (
        <h3 className='content-title'>{array[currentContent][0]}</h3>
      )}
      <div className='content-ingress'>
        <p>{ingress}</p>
      </div>
      <ul>
        {array[currentContent]
          .filter(row => row !== '')
          .map((row, i) => (
            <div key={i}>
              {' '}
              {Array.isArray(row) &&
                row.map((r, index) => (
                  <li className='listElement' key={i + index * 10}>
                    {' '}
                    {r}{' '}
                  </li>
                ))}
            </div>
          ))}
      </ul>
    </div>
  )

  return (
    <section className='split-page-categories' id={data.Block_Id}>
      <div
        className='split-page-image-wrapper'
        style={{
          backgroundImage: `url(${levandeSkargardar +
            data?.Background_Image?.url})`
        }}
      ></div>
      {(mobile || tablet) && (
        <>
          <div className={!show && show !== 0 ? 'hide' : 'category-wrapper'}>
            <div className='split-page-categeory-content'>
              <h1 className='category-title-h1'>{data.Title}</h1>
              {array.map((row, i) => (
                <div className='category-title-wrapper' key={i}>
                  <div className='icon-title-wrapper'>
                    <div className='icon-circle'>
                      <LazyLoadImage
                        src={levandeSkargardar + row[2]}
                        alt=''
                        className='icon-img'
                      />
                    </div>
                    <h2
                      onClick={() => {
                        setShow(() => {
                          return i === show ? false : i
                        })
                        setState(!state)
                        setCurrentContent(i)
                      }}
                      className={
                        show === i
                          ? 'title-underline category-title'
                          : 'category-title'
                      }
                    >
                      {row[0]}
                    </h2>
                  </div>
                  {show === i ? (
                    <div className='split-page-category-content-wrapper'>
                      <div className='ul-list'>{ulContent}</div>
                      <button className='button'>
                        <a href={`mailto:${row[4]}`}>{row[3]}</a>
                      </button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {(desktop || wideScreen) && (
        <>
          <div className={!show ? 'category-wrapper' : 'hide'}>
            <div className='split-page-categeory-content'>
              <h1 className='category-title-h1'>{data.Title}</h1>
              {array.map((row, i) => (
                <div
                  className='category-title-wrapper'
                  key={i}
                  onClick={() => {
                    setShow(true)
                    setCurrentContent(i)
                  }}
                >
                  <div className='icon-circle'>
                    <LazyLoadImage
                      src={levandeSkargardar + row[2]}
                      alt=''
                      className='icon-img'
                    />
                  </div>
                  <h3
                    className={
                      currentContent === i && show
                        ? 'title-underline category-title'
                        : 'category-title'
                    }
                  >
                    {row[0]}
                  </h3>
                </div>
              ))}
            </div>
          </div>
          {show && (
            <div className='split-page-category-content-wrapper'>
              <div className='ul-list'>
                {ulContent}
                <button className='button'>
                  <a href={`mailto:${array[currentContent][4]}`}>
                    {array[currentContent][3]}
                  </a>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </section>
  )
}
