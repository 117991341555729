import React, { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Page from './pages/Page'
import ReactGa from 'react-ga'

function App() {

	useEffect(() => {
		ReactGa.initialize("G-4LBR3S965Y");

		ReactGa.pageview("/");
	}, [])
 
  return (
		<Router>
			<div>
				<Switch>
					<Route exact path='/'>
						<Page />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}
export default App;
