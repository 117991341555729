import React, { useState } from 'react';
import '../scss/main.scss';
import {useTransition, animated} from 'react-spring';
import NavBar from '../components/Navbar';
import SikoLogo from '../assets/Sikologo_frslag.jpg'
import {useDevices} from "../util";
import { HashLink } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function NavbarMini() {
    const [mobile, tablet, desktop, wideScreen] = useDevices();
    const [showMenu, setShowMenu] = useState(false);
    const maskTransitions = useTransition(showMenu, null, {
        from: { position: 'absolute', opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    });        
    
    const menuTransitions = useTransition(showMenu, null, {
        from: { opacity: 0, transform: 'translateX(-100%)'},
        enter: { opacity: 1, transform: 'translateX(0%)'},
        leave: { opacity: 0, transform: 'translateX(-100%)'},
    });        

    function clickOnMenu(){
        setShowMenu(!showMenu)
    }

    function clickOnMenuHide(){
        setShowMenu(false)
    }

    return (
        <div className="navbar-wrapping-div">
            <div className="logo-div">
                <HashLink to={"/#SIKO"}><LazyLoadImage className="siko-logo" src={SikoLogo} alt="SikoLogga"/></HashLink>  
            </div>
           {(mobile || tablet) && <>
                <div className="icon">
                    <i className="fas fa-bars" onClick={clickOnMenu}></i>
                </div>
                {maskTransitions.map(({ item, key, props }) =>
                    item && <animated.div key={key} style={props} className="menu-mask" onClick={clickOnMenuHide}></animated.div>
                    )}
                {menuTransitions.map(({ item, key, props }) =>
                    item && <animated.div key={key} style={props} className="menu-class">
                        <div className="exit-div" onClick={clickOnMenuHide}>
                            <i className="fas fa-times fa-2x exit"></i>
                        </div>
                        <NavBar className={"mobile"} closeMenu={clickOnMenuHide}></NavBar>
                        <div className="logo-div-bottom">
                            <HashLink to={"/#SIKO"}><LazyLoadImage className="siko-logo-bottom" src={SikoLogo} alt="SikoLogga"/></HashLink>  
                        </div>
                    </animated.div>
                )}
            </>}
            {(desktop || wideScreen) &&
                <>
                    <NavBar closeMenu={clickOnMenuHide}></NavBar>
                </>
            }
        </div>
    )
}