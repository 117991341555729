import React, { useState, useLayoutEffect } from 'react';


/**
 * Get the window size as a react hook.
 */
export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        setTimeout(updateSize, 0)
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

/**
 * React hook that will define mobile, tablet and desktop booleans to be used
 * in components as a method to change the structure depending on the current
 * device screen size. Uses the breakpoints defined in the settings object.
 * 
 * Return format: [mobile, tablet, desktop]
 */
export function useDevices() {
    const [width, height] = useWindowSize();
    let mobile = width <= 600;
    let tablet = width > 600 && width <= 820;
    let desktop = width > 820 && width <= 1000;
    let wideScreen = width > 1000
    return [mobile, tablet, desktop, wideScreen];
}