import React, { useEffect, useState } from 'react'
import '../scss/main.scss'
import Checkmark from './Checkmark'
import RadioButton from './RadioButton'
import NumberInput from './NumberInput'
import ReactGa from 'react-ga'

let initialValue

export default function Form ({ setShowFormModal }) {
  const [phone, setPhone] = useState('')
  const [mail, setMail] = useState('')
  const [locallyEmployed, setLocallyEmployed] = useState([])
  const [islandInput, setIslandInput] = useState('')
  const [island, setIsland] = useState([])
  const [work, setWork] = useState([])
  const [home, setHome] = useState([])
  const [emailError, setEmailError] = useState('')
  const [adults, setAdults] = useState('')
  const [childrenInSchool, setChildrenInSchool] = useState('')
  const [children, setChildren] = useState('')
  const [status, setStatus] = useState([''])
  const [successForm, setSuccessFrom] = useState(false)
  const [showFrom, setShowForm] = useState(true)
  const [radio, setRadio] = useState([, , ,])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    initialValue = {
      island: island,
      islandInput: islandInput,
      work: work,
      locallyEmployed: locallyEmployed,
      home: home,
      adults: adults,
      childrenInSchool: childrenInSchool,
      children: children,
      radio: radio,
      phone: phone,
      mail: mail
    }
  }, [])

  function sendForm (e) {
    e.preventDefault()

    let sendData = {
      island: island,
      work: work,
      islandInput: islandInput,
      locallyEmployed: locallyEmployed,
      home: home,
      adults: adults,
      childrenInSchool: childrenInSchool,
      children: children,
      radio: radio,
      phone: phone,
      mail: mail
    }
    sendMail(sendData)
  }

  function closePupUp () {
    setShowFormModal(false)
    setSuccessFrom(false)
  }

  const successfullySentForm = (
    <>
      <div className='exit-div' onClick={closePupUp}>
        <i className='fas fa-times fa-2x exit'></i>
      </div>
      <div className='sent-form-content'>
        <h2 className='sent-form-h2'>
          Tack för din intresseförfrågan gällande skärgårdsflytten!
        </h2>
        <p className='sent-form-p'>
          Vi återkopplar till dig inom kort med nödvändig information och
          kontaktuppgifter. <br />
          <br />
          MVH SIKO
        </p>
      </div>
    </>
  )

  const sendMail = sendData => {
    let url =
      'https://server02.blackpixel.se:1402/send/0adb4ca66bab41e7d8f0db181397471d/d41d8cd98f00b204e9800998ecf8427e'

    if (mail === '' || mail === null) {
      setEmailError('Vänligen fyll i din email.')
    } else {
      setEmailError('')
    }

    if (mail != '') {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          subject: 'Meddelande från hemsidan - Levande Skärgård',
          text: `
                        <h2>Ni har fått ett meddelande från '<a href="mailto:${mail}">${mail}</a>':</h2>
                        <h2><b>Kunden har fyllt i:<b> </h2>
                        <p><b>Vilka öar vill du bo på:</b> ${sendData.islandInput +
                          ', ' +
                          sendData.island}</p>
                        <p><b>Hur vill/kommer du jobba:</b> ${sendData.work +
                          ', ' +
                          sendData.locallyEmployed}</p>
                        <p><b>Hur vill du bo:</b> ${sendData.home}</p>
                        <p><b>Vill du ha en mentor:</b> ${sendData.radio}</p>
                        <p><b>Antal vuxna:</b> ${sendData.adults}</p>
                        <p><b>Antal skolbarn (7-18 år):</b> ${
                          sendData.childrenInSchool
                        }</p>
                        <p><b>Antal barn (0-7 år):</b> ${sendData.children}</p>
                    `
        })
      }
      setIsLoading(true)
      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (!data.success) {
            setStatus([
              'Något gick fel :( Vänligen ring oss eller maila Levande Skärgård',
              'error'
            ])
          } else {
            setSuccessFrom(true)
            setShowForm(false)
            setStatus([
              'Tack för ditt meddelande, vi besvarar det så fort vi kan. Ha en härlig dag önskar vi på Levande Skärgård :)'
            ])
            setRadio([])
            setIsland([])
            setIslandInput('')
            setWork([])
            setHome([])
            setChildren('')
            setChildrenInSchool('')
            setAdults('')
            setPhone('')
            setMail('')
            setLocallyEmployed([])
            setIsLoading(false)
          }
        })
    } else {
      setStatus(['Kontrollera så att fälten är korrekt ifyllda', 'error'])
    }
  }

  function updateCheckmark (checked, name, state, setState) {
    checked
      ? setState([...state, name])
      : setState(state.filter(i => i !== name))
  }

  function updateInput (number, state, setState) {
    setState(number)
  }

  const locallyEmployedInput = (
    <>
      <Checkmark
        name='Har redan lokalt jobb'
        state={locallyEmployed}
        onChange={checked => {
          updateCheckmark(
            checked,
            'Har redan lokalt jobb',
            locallyEmployed,
            setLocallyEmployed
          )
        }}
      >
        Har redan lokalt jobb
      </Checkmark>
      <Checkmark
        name='Söker lokat jobb'
        state={locallyEmployed}
        onChange={checked => {
          updateCheckmark(
            checked,
            'Söker lokat jobb',
            locallyEmployed,
            setLocallyEmployed
          )
        }}
      >
        Söker lokat jobb
      </Checkmark>
    </>
  )

  function googleAnalytics () {
    ReactGa.event({
      category: 'Button',
      action: 'Sent form with button click'
    })
  }

  return (
    <>
      <div className={isLoading ? 'grey showLoader' : 'grey'}>
        {isLoading && (
          <div className='loader-wrapper'>
            <div className='loader'></div>
          </div>
        )}
      </div>
      <div className='sent-form-cover-screen-div'>
        <div
          className={successForm ? 'sent-form sent-form-thankYou' : 'sent-form'}
        >
          {!successForm ? (
            <>
              <div className='exit-div' onClick={closePupUp}>
                <i className='fas fa-times fa-2x exit'></i>
              </div>
              <div className='split-map-form-info'>
                <h3>Intresseanmälan</h3>
              </div>
              <form onSubmit={sendForm}>
                {/* Form */}
                <div className='form-wrapping-drop-down'>
                  <div className='form-drop-down-div'>
                    <span className='span-title'>
                      På vilken ö/öar vill du bo på?
                    </span>
                    <label>
                      <textarea
                        className='textarea'
                        onChange={e => {
                          setIslandInput(e.target.value)
                        }}
                        cols='32'
                        rows='7'
                      ></textarea>
                    </label>
                    <Checkmark
                      name='Spelar ingen roll'
                      state={island}
                      onChange={checked => {
                        updateCheckmark(
                          checked,
                          'Spelar ingen roll',
                          island,
                          setIsland
                        )
                      }}
                    >
                      Spelar ingen roll
                    </Checkmark>
                  </div>
                  <div className='form-drop-down-div'>
                    <span className='span-title'>Hur ser din familj ut?</span>
                    <NumberInput
                      name='Antal medlemmar i familjen'
                      updateParent={number => {
                        updateInput(number, adults, setAdults)
                      }}
                      state={adults}
                    >
                      Antal medlemmar i familjen
                    </NumberInput>
                    <NumberInput
                      name='Antal barn i förskoleålder'
                      state={childrenInSchool}
                      updateParent={number => {
                        updateInput(
                          number,
                          childrenInSchool,
                          setChildrenInSchool
                        )
                      }}
                    >
                      Antal barn i förskoleålder
                    </NumberInput>
                    <NumberInput
                      name='Antal barn i skolålder'
                      state={children}
                      updateParent={number => {
                        updateInput(number, children, setChildren)
                      }}
                    >
                      Antal barn i skolålder
                    </NumberInput>
                  </div>
                  <div className='form-drop-down-div'>
                    <span className='span-title'>Hur skulle du vilja bo?</span>
                    <Checkmark
                      name='Hus'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Hus', home, setHome)
                      }}
                    >
                      Hus
                    </Checkmark>
                    <Checkmark
                      name='Rum'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Rum', home, setHome)
                      }}
                    >
                      Rum
                    </Checkmark>
                    <Checkmark
                      name='Lägenhet'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Lägenhet', home, setHome)
                      }}
                    >
                      Lägenhet
                    </Checkmark>
                    <Checkmark
                      name='Hyra'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Hyra', home, setHome)
                      }}
                    >
                      Hyra
                    </Checkmark>
                    <Checkmark
                      name='Köpa'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Köpa', home, setHome)
                      }}
                    >
                      Köpa
                    </Checkmark>
                    <Checkmark
                      name='Prov-bo'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(checked, 'Prov-bo', home, setHome)
                      }}
                    >
                      Prov-bo
                    </Checkmark>
                    <Checkmark
                      name='Har redan boende'
                      state={home}
                      onChange={checked => {
                        updateCheckmark(
                          checked,
                          'Har redan boende',
                          home,
                          setHome
                        )
                      }}
                    >
                      Har redan boende
                    </Checkmark>
                  </div>
                  <div className='form-drop-down-div'>
                    <span className='span-title'>Hur tänker du jobba?</span>
                    <Checkmark
                      name='Distansjobba'
                      state={work}
                      onChange={checked => {
                        updateCheckmark(checked, 'Distansjobba', work, setWork)
                      }}
                    >
                      Distansjobba
                    </Checkmark>
                    <Checkmark
                      name='Pendla'
                      state={work}
                      onChange={checked => {
                        updateCheckmark(checked, 'Pendla', work, setWork)
                      }}
                    >
                      {' '}
                      Pendla
                    </Checkmark>
                    <Checkmark
                      name='Pensionär'
                      state={work}
                      onChange={checked => {
                        updateCheckmark(checked, 'Pensionär', work, setWork)
                      }}
                    >
                      {' '}
                      Pensionär
                    </Checkmark>
                    <span className='lokalt'>Lokalt</span>
                    {locallyEmployedInput}
                    <div className='locally-empl-option'></div>
                  </div>
                </div>
                <div className='form-wrapping-drop-down'>
                  <div className='radio-button-div'>
                    <p>Kontakt med en skärgårdsmentor?</p>
                    <div>
                      <div className='radio-div-wrapper'>
                        <RadioButton
                          name='mentor'
                          value='Ja tack'
                          radio={radio}
                          index='0'
                          setRadio={setRadio}
                        ></RadioButton>
                      </div>
                      <div className='radio-div-wrapper'>
                        <RadioButton
                          name='mentor'
                          value='Nej tack, inte ännu'
                          radio={radio}
                          index='1'
                          setRadio={setRadio}
                        ></RadioButton>
                      </div>
                    </div>
                  </div>
                  {/* Email */}
                  <div className='input-div-wrapper'>
                    <div className='input-div'>
                      <input
                        value={phone}
                        type='text'
                        className='send-form-input'
                        name='phone'
                        onChange={e => {
                          setPhone(e.target.value)
                        }}
                        placeholder='Telefon'
                      />
                    </div>
                    <div className='input-div'>
                      <input
                        value={mail}
                        type='text'
                        className='send-form-input'
                        name='mail'
                        onChange={e => {
                          setMail(e.target.value)
                        }}
                        placeholder='Mail'
                      />
                      {emailError && (
                        <p className='input-error-message'>{emailError}</p>
                      )}
                    </div>
                  </div>
                  <div className='button-div'>
                    <button
                      type='submit'
                      className='send-form-button'
                      onClick={googleAnalytics}
                    >
                      Skicka
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>{successfullySentForm}</>
          )}
        </div>
      </div>
    </>
  )
}
