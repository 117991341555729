import React, { useState } from 'react'

export default function Checkmark({state, onChange, children, name}) {
    const [checkmark, setCheckmark] = useState(false);
    const checkmarkShow = <i className="fas fa-check"></i> 
    
    function clickedCheckbox() {
        setCheckmark(!checkmark);
        if (onChange)onChange(!checkmark)
    }    
    
    return (
        <label className="checkbox-row">
            <span className="newCheckbox">{(state && Array.isArray(state) && state.some(x => x === name)) ? checkmarkShow : null}</span>
            <input type="checkbox" onChange={clickedCheckbox} className="form-checkbox" name={name} />{children}
        </label>
    )
}