import React from 'react'
import '../scss/main.scss'
import { useDevices } from '../util'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function WholePage ({ data }) {
  const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se'
  const [desktop] = useDevices()
  let title
  if (data.Title) {
    title = (
      <div>
        <h2>{data.Title}</h2>
      </div>
    )
  }
  let ingress
  if (data.Ingress) {
    ingress = (
      <div>
        {' '}
        <p className='split-page-ingress'>{data.Ingress}</p>
      </div>
    )
  }

  let text
  if (data.Text) {
    if (data.Text_Styling === 'Vanlig') {
      text = <p>{data.Text}</p>
    }

    if (data.Text_Styling === 'Radbrytning') {
      let textContent = data.Text?.split('\n')
      text = textContent.map((row, i) => {
        return (
          <div className='text-div'>
            <p key={i}>{row}</p>
          </div>
        )
      })
    }

    if (data.Text_Styling === 'Lista') {
      let textContent = data.Text?.split('\n')
      text = textContent.map((row, i) => {
        return (
          <div className='text-div'>
            <ul>
              <li key={i}>{row}</li>
            </ul>
          </div>
        )
      })
    }
  }
  return (
    <section className='side-by-side' id={data.Block_Id}>
      <div className='side-by-side-wrapper'>
        <div
          className={`split-page-content-left split-page-content-wrapper split-page-content-${data.Content_Background_Color}`}
        >
          <div
            className={`split-page-text split-page-text-color-${data.Text_Color}`}
          >
            <div className='split-page-text-wrapper extra-padding split-map-form-info'>
              {title}
              {ingress}
              {text}
            </div>
          </div>
        </div>
        <div
          className={`split-page-content-right split-page-content-wrapper split-page-content-${data.Content_Background_Color}`}
        >
          <div
            className={`split-page-text split-page-text-color-${data.Text_Color}`}
          >
            <div className='split-page-text-wrapper'>
              <div className='links organisations'>
                {data?.Organisations?.map(org => (
                  <a href={org.Organisation_Link} target='_blank'>
                    {org.Title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
