import React from 'react'
import '../scss/main.scss'

export default function Hero ({ data }) {
  const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se'

  return (
    <section className='hero-block' id={data.Block_Id}>
      <div
        className='hero-image-wrapper'
        style={{
          backgroundImage: `url(${levandeSkargardar +
            data?.Background_Image?.url})`
        }}
      >
        <div className='hero-filter'></div>
      </div>
      <div className='hero-content'>
        <h2>{data.Title}</h2>
        <p className='hero-ingress'>{data.Ingress}</p>
        <p className='hero-text'>{data.Text}</p>
      </div>
      {(data.Button1 || data.Button2) && (
        <div className='hero-button-wrapper'>
          {data.Button1 && (
            <button className='hero-button'>
              <a target='_blank' href={data.Button_Link1}>
                {data.Button1}
              </a>
            </button>
          )}
          {data.Button2 && (
            <button className='hero-button'>
              {' '}
              <a a target='_blank' href={data.Button_Link2}>
                {data.Button2}
              </a>
            </button>
          )}
        </div>
      )}
    </section>
  )
}
