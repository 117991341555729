import React, { useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  InfoWindow,
  Marker
} from '@react-google-maps/api'
import '../scss/main.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se'

export default function Map ({ data, current, setCurrent, show, setShow }) {
  const [selected, setSelected] = useState(false)

  const toggleInfo = key => {
    let showCopy = {}
    showCopy[key] = !show[key]
    setShow(showCopy)
    setSelected({})
  }

  const onSelect = island => {
    setCurrent(island)
  }

  const mapStyles = {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0'
  }

  const center = {
    lat: 59.4249724,
    lng: 18.5308843
  }

  return (
    <div className='container-map'>
      <div className={`map-container`}>
        <LoadScript googleMapsApiKey='AIzaSyCq_Xk-UDPlWWQEKplYC4JLTPExZKa_Fhw'>
          <GoogleMap mapContainerStyle={mapStyles} zoom={9} center={center}>
            {/* Karta 1 */}
            {data.Islands &&
              data.Islands.map(island => {
                return (
                  <>
                    <Marker
                      key={island.Title}
                      title={island.Title}
                      position={{ lat: island.Y, lng: island.X }}
                      onClick={() => {
                        onSelect(island)
                        toggleInfo(island.Title)
                      }}
                    >
                      {show[island.Title] && (
                        <InfoWindow
                          position={{ lat: selected.Y, lng: selected.X }}
                          clickable={true}
                          onCloseClick={() => {
                            setSelected({})
                            toggleInfo(island.Title)
                          }}
                        >
                          <div className='pop-up-info-div'>
                            <div>
                              <h4 className='pop-up-title'>{current.Title}</h4>
                            </div>
                            <div>
                              <p className='pop-up-text'>{current.Text}</p>
                            </div>
                            <div>
                              <LazyLoadImage
                                className='pop-up-image'
                                src={levandeSkargardar + current?.Image?.url}
                                alt=''
                              />
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  </>
                )
              })}

            {/* Karta 2 */}
            {data.Organisations &&
              data.Organisations.map(org => {
                return (
                  <>
                    <Marker
                      key={org.Title}
                      title={org.Title}
                      position={{ lat: org.Y, lng: org.X }}
                      onClick={() => {
                        onSelect(org)
                        toggleInfo(org.Title)
                      }}
                    >
                      {show[org.Title] && current && (
                        <InfoWindow
                          position={{ lat: current.Y, lng: current.X }}
                          clickable={true}
                          onCloseClick={() => {
                            setSelected({})
                            toggleInfo(org.Title)
                          }}
                        >
                          <div className='pop-up-info-div'>
                            <div>
                              <h4 className='pop-up-title'>{current.Title}</h4>
                            </div>
                            <div>
                              <p className='pop-up-text'>{current.Text}</p>
                            </div>
                            <div>
                              <a
                                className='pop-up-text'
                                href={current.Organisation_Link}
                              >
                                <b>{current.Organisation_Link}</b>
                              </a>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  </>
                )
              })}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}
