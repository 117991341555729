import React from 'react'

export default function NumberInput({state, updateParent, children, name}) {

    function updateNumber(e) {
        if (updateParent)updateParent(e.target.value)
    }   

    return (
        <label>
            <input type="number" onChange={updateNumber} name={name} value={state} className="family-members"/>{children}
        </label>
    );
}