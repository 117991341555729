import React from 'react'
import '../scss/main.scss'
import { useDevices } from '../util'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function SplitPage ({ data }) {
  const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se'
  const [desktop] = useDevices()

  let title
  if (data.Title) {
    title = (
      <div>
        <h2>{data.Title}</h2>
      </div>
    )
  }
  let ingress
  if (data.Ingress) {
    ingress = (
      <div>
        {' '}
        <p className='split-page-ingress'>{data.Ingress}</p>
      </div>
    )
  }

  let text
  if (data.Text) {
    if (data.Text_Styling === 'Vanlig') {
      text = <p>{data.Text}</p>
    }

    if (data.Text_Styling === 'Radbrytning') {
      let textContent = data.Text?.split('\n')
      text = textContent.map((row, i) => {
        return (
          <div className='text-div'>
            <p key={i}>{row}</p>
          </div>
        )
      })
    }

    if (data.Text_Styling === 'Lista') {
      let textContent = data.Text?.split('\n')
      text = textContent.map((row, i) => {
        return (
          <div className='text-div'>
            <ul>
              <li key={i}>{row}</li>
            </ul>
          </div>
        )
      })
    }
  }

  let button
  if (data.Button1 !== null) {
    if (data.Content_placement === 'Left') {
      button = (
        <button className='button'>
          {' '}
          <a a target='_blank' href={data.Button_Link}>
            {data.Button1}
          </a>
        </button>
      )
    }
    if (data.Content_placement === 'Right') {
      button = (
        <button className='button-right'>
          {' '}
          <a a target='_blank' href={data.Button_Link}>
            {data.Button1}
          </a>
        </button>
      )
    }
  }

  let samePlacement

  if (data.Logos_Placement === data.Content_placement) {
    samePlacement = true
  } else {
    samePlacement = false
  }

  let logoImagesWrapper
  let logoImages
  if (data.Logo_Images !== null) {
    logoImages = data.Logo_Images.map(oneLogo => {
      return (
        <div key={oneLogo.id} className='logoImages-div'>
          <LazyLoadImage
            src={levandeSkargardar + oneLogo?.url}
            alt='Logga'
            className='logoImage'
          />
        </div>
      )
    })
    if (samePlacement || !desktop) {
      logoImagesWrapper = <div className={`split-page-logos`}>{logoImages}</div>
    }
    if (!samePlacement && desktop) {
      logoImagesWrapper = (
        <div className={`split-page-logos-${data.Logos_Placement}`}>
          {logoImages}
        </div>
      )
    }
  }

  return (
    <section className='split-page-block' id={data.Block_Id}>
      <div
        className='split-page-image-wrapper'
        style={{
          backgroundImage: `url(${levandeSkargardar + data?.Image?.url})`
        }}
      ></div>
      <div
        className={`split-page-content-${data.Content_placement} split-page-content-wrapper split-page-content-${data.Content_Background_Color}`}
      >
        <div
          className={`split-page-text split-page-text-color-${data.Text_Color}`}
        >
          <div className='split-page-text-wrapper'>
            {title}
            {ingress}
            {text}
            <div className='split-page-button-div'>{button}</div>
          </div>
        </div>
        {logoImagesWrapper}
      </div>
    </section>
  )
}
