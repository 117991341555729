import React, { useRef, useState } from 'react'
import Form from '../components/Form';
import '../scss/main.scss';
import Map from '../components/Map';

export default function SplitMap({data}) {
  const [current, setCurrent] = useState({});
  const [showFormModal, setShowFormModal] = useState(false);
  const [show, setShow] = useState({});
  const ref = useRef()

  let title;
  if (data.Title) {
      title = <div><h2>{data.Title}</h2></div>
  }
  let ingress;
  if (data.Ingress) {
      ingress = <p className="split-map-ingress">{data.Ingress}</p>
  }
  let text;
  if (data.Text) {
      text = <p>{data.Text}</p>
  }

  return (
    <section className="split-map-block" id={data.Block_Id}>

      {/* Karta */}
        {data.Show_Map &&
          <Map data={data} show={show} setShow={setShow} current={current} setCurrent={setCurrent}></Map>        
        }

      <div
        className={`split-map-content-${data.Content_Placement} split-map-content-wrapper split-map-content-${data.Content_Background_Color} split-page-text-color-${data.Text_Color}`}
      >
        <div className="content-padding" ref={ref}>
          <div className="split-map-form-info">
            {title}
            {ingress}
            {text}
          </div>
        <div className="split-map-button-div">
          {data.Form_Button && <button className="split-map-button" onClick={() => {setShowFormModal(true)}}>{data.Form_Button}</button>}
            {showFormModal && 
              <div>
                {data.Show_Form && (
                  <Form data={data} setShowFormModal={setShowFormModal} />
                )}
              </div>
            }
        </div>
        </div>
      </div>
    </section>
  );
}
