import React, { useEffect, useState } from 'react'
import {useDevices} from "../util";
import Map from '../components/Map';

export default function SplitOrganisation({data}) {
    const [mobile, tablet, desktop, wideScreen] = useDevices();
    const [current, setCurrent] = useState({});
    const [show, setShow] = useState({});

    const onSelect = island => {
        setCurrent(island);
    }
    
    let title;
    if (data.Title) {
        title = <div><h2 className="org-title">{data.Title}</h2></div>
    }
    let ingress;
    if (data.Title) {
        ingress = <div><p>{data.Ingress}</p></div>
    }
    let text;
    if (data.Text) {
        text = <div><p>{data.Text}</p></div>
    }

    return (
        <>
        <div className="section">
            <section className="org-block" id={data.Block_Id}>
                <div className="org-content">
                    {title}
                    {ingress}
                    {text}
                </div>
                <div className="section-org">
                    <div className="org-content">
                        {!wideScreen && 
                            <>
                                <div className="select-div">                                    
                                    {
                                        data.Show_Map &&
                                            <Map show={show} setShow={setShow} current={current} setCurrent={setCurrent} data={data}></Map>
                                    }   
                                </div>
                            </>
                        }
                        {wideScreen && 
                            <>
                                <ul>
                                    {data.Organisations.map((oneIsland, i) => 
                                        <>
                                            <li className={`${show[oneIsland.Title] == true ? 'org-underline' : 'org-option'}`} 
                                                key={oneIsland.Title} value={i} onClick={() => {
                                                let infoPopUp = {}                                                
                                                infoPopUp[oneIsland.Title] = !show[oneIsland.Title];
                                                onSelect(oneIsland)
                                                setCurrent(oneIsland); 
                                                setShow(infoPopUp) 
                                                }}>{oneIsland.Title}</li> 
                                        </>
                                    )}
                                </ul>
                            </> 
                        }
                    </div>
                </div>
            </section>
            {wideScreen && 
                data.Show_Map &&
                    <Map show={show} setShow={setShow} current={current} setCurrent={setCurrent} data={data}></Map>
            }
        </div>
        </>
    )
}