import React from 'react'
import '../scss/main.scss';
import { HashLink } from 'react-router-hash-link';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Footer({data}) {
    const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se';
    const footerLogo = levandeSkargardar + data?.Logo?.Logo?.url;    

    return (
        <footer className="footer-wrapper">
            <div className="meny-div">
                <ul className="ul">
                    {data.Meny.Meny_Item.map((meny) => (
                        <li className="li" key={meny.Meny_Title}><HashLink to={"/#" + meny.Meny_Title}>{meny.Meny_Title}</HashLink></li>
                    ))}
                </ul>
            </div>
            <div className="footer-img-div">
                <LazyLoadImage className="footer-img" src={footerLogo} alt=""/>
            </div>
        </footer>
    )
}