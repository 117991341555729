import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Hero from '../blocks/Hero'
import SplitPage from '../blocks/SplitPage'
import SplitMap from '../blocks/SplitMap'
import SplitPageCategories from '../blocks/SplitPageCategories'
import Modal from '../components/Modal'
import NavbarMini from '../components/NavbarMini'
import SplitOrganisation from '../blocks/SplitOrganisation'
import Footer from '../blocks/Footer'
import Corona from '../components/Corona'
import WholePage from '../blocks/WholePage.js'

export default function Page () {
  const [response, setResponse] = useState([])
  const [showModal, setShowModal] = useState(false)
  // const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se/pages/1'
  // const levandeSkargardar = 'http://192.168.1.188:1337/pages/1'
  const levandeSkargardar = 'https://api.xn--levandeskrgrd-jfbr.se/pages/1'

  useEffect(() => {
    axios.get(levandeSkargardar).then(res => {
      let response = res.data
      setResponse(response)
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true)
    }, 1000)
  }, [])

  return (
    <>
      {/* {showModal ? <Corona /> : null} */}
      <NavbarMini />
      {response.content &&
        response.content.map((block, i) => {
          if (block.__component === 'page-block.hero') {
            return <Hero key={block.id} data={block} key={i} />
          }
          if (block.__component === 'page-block.split-page') {
            return <SplitPage key={block.id} data={block} key={i} />
          }
          if (block.__component === 'page-block.split-map') {
            return <SplitMap key={block.id} data={block} key={i} />
          }
          if (block.__component === 'page-block.split-page-categories') {
            return <SplitPageCategories key={block.id} data={block} key={i} />
          }
          if (block.__component === 'page-block.split-page-org') {
            return <SplitOrganisation key={block.id} data={block} key={i} />
          }
          if (block.__component === 'page-block.footer') {
            return <Footer key={block.id} data={block} key={i} />
          }
          if (block.__component === 'content.whole-page') {
            return <WholePage key={block.id} data={block} key={i} />
          }
        })}
    </>
  )
}
