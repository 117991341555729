import React from 'react'

export default function RadioButton({radio, setRadio, value, children, name}) {
    const bulletShow = <i className="fas fa-circle"></i>

    return (
        <div className="radio-div-wrapper" onClick={(() => setRadio(value))}>
            <span className="newRadioButton">{radio === value ? bulletShow : null}</span>
            <input type="radio" value={value} className="checkbox" name={name}/>{children}
            <label htmlFor={value}>{value}</label>
        </div>
    )
}