import React from 'react'
import '../scss/main.scss';
import { HashLink } from 'react-router-hash-link';

export default function Navbar(props) {
 
    return (
        <div className={`navbar-ul-div`}>
            <ul className={`navbar-ul ${props.className}`}>
                <HashLink to="/#Sikos Vision" onClick={props.closeMenu}><li className="navbar-li">Sikos Vision</li></HashLink>                    
                <HashLink to="/#Skärgårdsflytten" onClick={props.closeMenu}><li className="navbar-li">Skärgårdsflytten</li></HashLink>
                <HashLink to="/#Fokusområden" onClick={props.closeMenu}><li className="navbar-li">Fokusområden</li></HashLink>
                <HashLink to="/#Medlemsföreningar" onClick={props.closeMenu}><li className="navbar-li">Medlemsföreningar</li></HashLink>
                <a href="http://siko.org.se/"><li className="navbar-li">Medlem</li></a>
                <a href="http://siko.org.se/"><li className="navbar-li">Kontakt</li></a>
            </ul>
        </div>
    )
}